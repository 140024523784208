import {
  BottomSheetProps,
  DefaultPopupProps,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { commonStoreForm, Set } from "utils/store";
import { ReactComponent as Example } from "assets/2.0/search.svg";

type ModalStoreState = {
  defaultPopup: DefaultPopupProps<"default">;
  secondaryPopup: DefaultPopupProps<"secondary">;
  bottomSheet: BottomSheetProps;
};

const initialState: ModalStoreState = {
  defaultPopup: {
    isOpen: false,
    types: "default",
    title: "",
    content: "",
    btnText: "",
    onClick: () => console.log("click"),
  },
  secondaryPopup: {
    isOpen: false,
    types: "secondary",
    title: "",
    content: "",
    btnText: "",
    onClick: () => console.log("click"),
    btnTextSub: "",
    onClickSub: () => console.log("sub click"),
  },
  bottomSheet: {
    isOpen: false,
    title: "",
    SVGComponent: Example,
    firstContent: "",
    secondContent: "",
    btnText: "",
    onClickConfirm: () => console.log("click confirm"),
    onClose: () => console.log("close"),
  },
};

type Action = {
  openDefaultPopupWithSet: (
    props: Omit<DefaultPopupProps<"default">, "isOpen">
  ) => void;
  openSecondaryPopupWithSet: (
    props: Omit<DefaultPopupProps<"secondary">, "isOpen">
  ) => void;
  openBottomSheetWithSet: (props: Omit<BottomSheetProps, "isOpen">) => void;
  closeAll: () => void;
};

const action = (set: Set<ModalStoreState, Action>): Action => ({
  openDefaultPopupWithSet: (props) =>
    set((state) => ({
      defaultPopup: { ...props, isOpen: true },
      secondaryPopup: { ...state.secondaryPopup, isOpen: false },
      bottomSheet: { ...state.bottomSheet, isOpen: false },
    })),
  openSecondaryPopupWithSet: (props) =>
    set((state) => ({
      secondaryPopup: { ...props, isOpen: true },
      defaultPopup: { ...state.defaultPopup, isOpen: false },
      bottomSheet: { ...state.bottomSheet, isOpen: false },
    })),
  openBottomSheetWithSet: (props) =>
    set((state) => ({
      bottomSheet: { ...props, isOpen: true },
      defaultPopup: { ...state.defaultPopup, isOpen: false },
      secondaryPopup: { ...state.secondaryPopup, isOpen: false },
    })),
  closeAll: () =>
    set((state) => ({
      defaultPopup: { ...state.defaultPopup, isOpen: false },
      secondaryPopup: { ...state.secondaryPopup, isOpen: false },
      bottomSheet: { ...state.bottomSheet, isOpen: false },
    })),
});

const useModalStore = commonStoreForm<ModalStoreState, Action>({
  initialState,
  action,
});

export default useModalStore;
