import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Filter,
  FloatingIconBtn,
  JobCard,
  OtherBtn,
  TextIconBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { useNavigate } from "react-router-dom";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import ResultList from "./result/ResultList";

import {
  getJobAlarm,
  getJobListCustom,
  jobKeys,
  patchJobAlarm,
} from "apis/job";
import { useBackPressHandler } from "hooks/useBackPressHandler";
import useJobSearchInfoStore from "stores/jobSearchInfo";
import useModalStore from "stores/modal";
import { calculateDifferentDays } from "utils/date";

import { ReactComponent as AIService } from "assets/2.0/aiService.svg";
import { ReactComponent as Search } from "assets/2.0/search.svg";
import { ReactComponent as Bell } from "assets/2.0/bell.svg";
import { ReactComponent as Alert } from "assets/2.0/alert.svg";
import { ReactComponent as Loading } from "assets/2.0/loading.svg";
import styled from "styled-components";
import { differenceInCalendarDays } from "date-fns";

const MyResult = () => {
  const navigate = useNavigate();

  const {
    openBottomSheetWithSet,
    openDefaultPopupWithSet,
    closeAll,
    openSecondaryPopupWithSet,
  } = useModalStore((state) => state.action);
  const { setClearAll } = useJobSearchInfoStore((state) => state.action);

  const [orderKey, setOrderKey] = useState<"deadline" | "recent">("recent");
  const [isEmptyRecommend, setIsEmptyRecommned] = useState<boolean>(false);

  const listRef = useRef<HTMLUListElement>(null);

  const locationList = localStorage.getItem("location") ?? "";
  const keywordIdList = localStorage.getItem("keyword") ?? "";
  const names = localStorage.getItem("names") ?? "";

  const {
    data: customData,
    fetchNextPage: customFetchNextPage,
    hasNextPage: customHasNextPage,
    isFetchingNextPage: customIsFetchingNextPage,
    refetch: customRefetch,
  } = useInfiniteQuery({
    queryKey: jobKeys.getJobListCustom(
      !!locationList ? JSON.parse(locationList) : "",
      !!names
        ? JSON.parse(names)
        : !!keywordIdList
        ? JSON.parse(keywordIdList)
        : ""
    ),
    queryFn: async ({ pageParam }) =>
      await getJobListCustom({ page: pageParam, orderKey }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.data.result.nextUrl
        ? Number(lastPage.data.result.nextUrl.split("page=")[1])
        : undefined;
    },
    select: (response) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = response.pages.reduce((acc: any[], val) => {
        const concatArr = val.data.result.jobInfoList
          ? acc.concat(val.data.result.jobInfoList)
          : acc;
        return concatArr;
      }, []);

      const totalCount = response.pages[0].data.result.totalCount;

      const todayData = data.filter(({ regDate }) => {
        if (!regDate) {
          return false;
        } else {
          const daysFromReg = differenceInCalendarDays(
            new Date(),
            new Date(regDate[0], regDate[1] - 1, regDate[2])
          );

          return daysFromReg < 1 ? true : false;
        }
      });

      return { data, totalCount, newJobsNum: todayData.length };
    },
  });

  const FILTER_LIST = [
    {
      id: 0,
      text: "마감 순",
      onClick: () => setOrderKey("deadline"),
    },
    {
      id: 1,
      text: "최신 순",
      onClick: () => setOrderKey("recent"),
    },
  ];

  const { data: isApplyAlarm } = useQuery({
    queryKey: jobKeys.getJobAlarm,
    queryFn: async () => await getJobAlarm(),
    select: (response) => response.data.result,
  });

  const { mutateAsync } = useMutation({
    mutationFn: async () => await patchJobAlarm(true),
    onSuccess: async () => {
      await openDefaultPopupWithSet({
        title: "알림 신청 완료!",
        content: "AI가 새로운 일자리를 발견하면 바로 알려드릴게요.",
        btnText: "확인",
        onClick: closeAll,
      });
    },
  });

  const customObserverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      !customObserverRef.current ||
      !customHasNextPage ||
      customIsFetchingNextPage
    )
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        customFetchNextPage();
      }
    });

    observer.observe(customObserverRef.current);

    return () => observer.disconnect();
  }, [customHasNextPage, customIsFetchingNextPage, customFetchNextPage]);

  const onClickChange = async () => {
    navigate("/confirm", { state: { mode: "MODIFY" } });
  };

  const onClickAlert = () => {
    openBottomSheetWithSet({
      title: "맞춤형 일자리 추천\n알림을 신청하시겠어요?",
      firstContent: "AI가 맞춤형 일자리를 찾을 때마다 카카오톡으로 알려드려요.",
      secondContent: "알림이 오지 않을 경우 차단 여부를 확인해주세요",
      SVGComponent: Alert,
      btnText: "알림 신청하기",
      onClickConfirm: async () => {
        if (isApplyAlarm) {
          await openDefaultPopupWithSet({
            title: "이미 알림을 신청했어요",
            content: "AI가 새로운 일자리를 발견하면 바로 알려드릴게요.",
            btnText: "확인",
            onClick: closeAll,
          });
        } else {
          await mutateAsync();
        }
      },
      onClose: closeAll,
    });
  };

  const onClickScroll = () => {
    if (listRef.current) {
      listRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const onClickCard = (id: number) => {
    navigate(`${id}`);
  };

  const onBackPressHandler = useCallback(async () => {
    openSecondaryPopupWithSet({
      types: "secondary",
      title: "정보를 변경하고 싶으신가요?",
      content: "확인을 누르면 맞춤 정보를 수정 가능한 화면으로 이동합니다.",
      btnText: "확인",
      onClick: () => {
        navigate("/confirm");
        closeAll();
      },
      btnTextSub: "취소",
      onClickSub: () => {
        closeAll();
      },
    });
  }, [closeAll, navigate, openSecondaryPopupWithSet]);

  const onClickReSearch = () => {
    const removeKeyList = ["keyword", "names", "location"];
    openBottomSheetWithSet({
      title: "새로운 정보로\n일자리를 검색하시겠어요?",
      firstContent: "지금까지 저장된 일자리 정보가 삭제돼요.",
      secondContent:
        "알림을 받고 있다면 변경된 정보로 다시 알림 받기를 신청해주세요.",
      SVGComponent: Search,
      btnText: "새로 일자리 검색하기",
      onClickConfirm: () => {
        removeKeyList.forEach((k) => localStorage.removeItem(k));
        setClearAll();
        navigate("/chat");
        closeAll();
      },
      onClose: closeAll,
    });
  };

  useBackPressHandler({
    backPressHandler: onBackPressHandler,
    historyStateKey: "myResultBackPressPopup",
  });

  useEffect(() => {
    customRefetch();
  }, [customRefetch, orderKey]);

  useEffect(() => {
    if (!locationList || (!names && !keywordIdList)) {
      navigate("/chat", { replace: true });
    }
  }, [locationList, names, keywordIdList, navigate]);

  return (
    <Container>
      <p>AI 일자리 찾기</p>
      <ButtonSection>
        <section>
          <OtherBtn types={"primary"} onClick={onClickChange} />
          <OtherBtn types={"secondary"} onClick={onClickAlert} />
        </section>
        <FilterSection>
          <p>
            {`총 `}
            <b>{`${customData?.totalCount}`}</b>
            {`개의 일자리를 찾았어요`}
          </p>
          <Filter btnList={FILTER_LIST} align="right" alignValue={0.1}>
            {orderKey === "deadline" ? "마감 순" : "최신 순"}
          </Filter>
        </FilterSection>
      </ButtonSection>
      <List ref={listRef}>
        {!!customData?.newJobsNum && (
          <NewJobs>
            <AIService width={32} height={32} />
            <p>
              새로운 일자리를 <span>{`${customData.newJobsNum}`}</span>건
              찾았어요
            </p>
          </NewJobs>
        )}
        {!isEmptyRecommend ? (
          customData && customData.data.length > 0 ? (
            customData.data.map(
              (
                { id, company, location, name, type, salary, deadline },
                idx
              ) => {
                const calculateDate =
                  deadline && deadline.length > 0
                    ? calculateDifferentDays(
                        new Date(deadline[0], deadline[1] - 1, deadline[2])
                      )
                    : 0;
                return (
                  <li key={idx}>
                    <JobCard
                      hasExpired={
                        deadline && deadline.length > 0 && !isNaN(calculateDate)
                      }
                      expiredDay={calculateDate}
                      onClick={() => onClickCard(id)}
                      title={name}
                      infoList={[
                        { id: 0, key: "기업", value: company ?? "-" },
                        { id: 1, key: "위치", value: location ?? "-" },
                        { id: 2, key: "고용형태", value: type ?? "-" },
                        { id: 3, key: "급여", value: salary ?? "-" },
                      ]}
                    />
                  </li>
                );
              }
            )
          ) : (
            <EmptyView>
              <p>{"지금은 딱 맞는 일자리가 없나봐요."}</p>
              <span>
                {"AI가 새로운 일자리를 찾을 때까지\n조금만 기다려주세요!"}
              </span>
              <TextIconBtn SVGComponent={Bell} onClick={onClickAlert}>
                {"새로운 일자리 알림 받기"}
              </TextIconBtn>
            </EmptyView>
          )
        ) : null}
        <div ref={customObserverRef} style={{ marginTop: "-2rem" }} />
        {!customHasNextPage && (
          <ResultList
            onClickAlert={onClickAlert}
            setIsEmptyRecommned={setIsEmptyRecommned}
          />
        )}
        <ReSearchBtn>
          <TextIconBtn SVGComponent={Loading} onClick={onClickReSearch}>
            {"다시 검색"}
          </TextIconBtn>
        </ReSearchBtn>
      </List>
      {!isEmptyRecommend && (
        <FloatingIconBtn onClick={onClickScroll} bottom={3} right={0.9} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > p {
    padding: 2.2rem 1.6rem 1.5rem;
    ${typography.heading2};
    color: var(--color-black);
  }
`;

const ButtonSection = styled.div`
  padding: 2rem 1.6rem 1rem;

  section {
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;
  }
`;

const FilterSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    ${typography.body2};
    color: var(--color-gray-800);
    b {
      ${typography.heading3}
    }
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  padding: 1.5rem 0rem 10rem;
  overflow-y: auto;
  background-color: var(--color-gray-50);

  li {
    width: 100%;
    padding: 0rem 1.6rem;

    & > button {
      width: 100%;
    }
  }
`;

const NewJobs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem;
  margin: 0rem 1.6rem;
  border-radius: 1rem;
  background-color: var(--color-white);

  p {
    ${typography.preTitle};
    color: var(--color-gray-900);

    span {
      color: var(--color-yellow-4);
    }
  }
`;

const EmptyView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  padding: 4.8rem 3.2rem;
  background-color: var(--color-white);

  & > p {
    ${typography.heading3};
    color: var(--color-gray-800);
  }

  & > span {
    ${typography.body2};
    color: var(--color-gray-700);
    text-align: center;
    margin-bottom: 1.6rem;
  }
`;

const ReSearchBtn = styled.div`
  display: flex;
  justify-content: center;
`;

export default MyResult;
