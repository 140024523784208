import { useEffect } from "react";

export const useBackPressHandler = (params: {
  backPressHandler: () => void; // Recommend to use useCallback
  historyStateKey: string;
}) => {
  const { backPressHandler, historyStateKey } = params;

  useEffect(() => {
    if (window.history.state && !window.history.state[historyStateKey]) {
      window.history.pushState({ [historyStateKey]: true }, "");
    }

    window.addEventListener("popstate", backPressHandler);

    return () => {
      window.removeEventListener("popstate", backPressHandler);
    };
  }, [backPressHandler, historyStateKey]);

  return null;
};
