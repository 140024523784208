import { analytics } from "config/firebaseConfig";
import { logEvent } from "firebase/analytics";

const trackScreenView = (pathname: string) => {
  if (analytics) {
    logEvent(analytics, "screen_view", {
      firebase_screen: pathname,
      firebase_screen_class: "App",
    });
  } else {
    console.log("Google Analytics is disabled in this environment.");
  }
};

export default trackScreenView;
