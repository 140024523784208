import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  DefaultBtn,
  GuideChatBox,
  ResponseChatBox,
  TextChevronBtn,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { useNavigate } from "react-router-dom";

import KeywordChat from "./search/KeywordChat";
import { useBackPressHandler } from "hooks/useBackPressHandler";

import useJobSearchInfoStore from "stores/jobSearchInfo";
import useModalStore from "stores/modal";
import styled from "styled-components";

const Chat = () => {
  const navigate = useNavigate();
  const names = useJobSearchInfoStore((state) => state.names);
  const locationList = useJobSearchInfoStore((state) => state.locationList);

  const [isNextWithInput, setIsNextWithInput] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const isNotRecommended = useJobSearchInfoStore(
    (state) => state.isNotRecommended
  );
  const step = useJobSearchInfoStore((state) => state.step);

  const communication = useJobSearchInfoStore(
    (state) => state.keywordIdList.communication
  );
  const computer = useJobSearchInfoStore(
    (state) => state.keywordIdList.computer
  );
  const computerDetail = useJobSearchInfoStore(
    (state) => state.keywordIdList.computerDetail
  );
  const home = useJobSearchInfoStore((state) => state.keywordIdList.home);
  const location = useJobSearchInfoStore(
    (state) => state.keywordIdList.location
  );
  const physical = useJobSearchInfoStore(
    (state) => state.keywordIdList.physical
  );
  const preferSit = useJobSearchInfoStore(
    (state) => state.keywordIdList.preferSit
  );
  const write = useJobSearchInfoStore((state) => state.keywordIdList.write);

  const { openSecondaryPopupWithSet, closeAll } = useModalStore(
    (state) => state.action
  );

  const onBackPressHandler = useCallback(async () => {
    openSecondaryPopupWithSet({
      types: "secondary",
      title: "일자리 찾기를 종료할까요?",
      content: "지금까지 입력한 정보가 초기화됩니다. 정말 종료할까요?",
      btnText: "확인",
      onClick: () => {
        navigate(-2);
        closeAll();
      },
      btnTextSub: "취소",
      onClickSub: () => {
        closeAll();
      },
    });
  }, [closeAll, navigate, openSecondaryPopupWithSet]);

  const onClickHandler = () => {
    setIsNextWithInput(true);
  };

  const onClickBtn = () => {
    navigate("/address");
  };

  // Prevent to interact with backpress in AOS / Web
  useBackPressHandler({
    backPressHandler: onBackPressHandler,
    historyStateKey: "chatBackPressPopup",
  });

  const onClickKeyword = () => {
    navigate("/confirm", { replace: true });
  };

  useEffect(() => {
    if (isNextWithInput && names.length > 0) {
      onClickKeyword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNextWithInput, names, navigate]);

  const keywordBtnDisabled = useMemo(() => {
    if (
      !!communication &&
      !!computer &&
      !!home &&
      !!location &&
      !!physical &&
      !!preferSit &&
      !!write
    ) {
      if (computer.value === "possible") {
        if (!!computerDetail) return false;
        else return true;
      } else return false;
    } else {
      return true;
    }
  }, [
    communication,
    computer,
    computerDetail,
    home,
    location,
    physical,
    preferSit,
    write,
  ]);

  return (
    <>
      <Container>
        <ScrollContainer>
          {/* Default */}
          <GuideChatBox
            title={
              "환영합니다! 몇 가지 정보만 알려주시면 맞춤형 일자리 추천을 해드릴게요!"
            }
          />
          <GuideChatBox title={"근무를 원하는 지역을 입력해주세요."} />
          {/* Location */}
          {!locationList.length ? (
            <TextChevronBtn onClick={onClickBtn} width={15.8}>
              {"지역 선택하기"}
            </TextChevronBtn>
          ) : (
            <ResponseChatBox onClickModify={onClickBtn}>
              {locationList
                .map(({ city, district }) => `${city} ${district}`)
                .join(", ")}
            </ResponseChatBox>
          )}
          {/* Keyword */}
          <KeywordChat
            isNextWithInput={isNextWithInput}
            setDisabled={setDisabled}
          />
        </ScrollContainer>
      </Container>
      {isNotRecommended === true && (
        <ButtonSection>
          <DefaultBtn disabled={disabled} onClick={onClickHandler}>
            {"입력하기"}
          </DefaultBtn>
        </ButtonSection>
      )}
      {step >= 8 && (
        <ButtonSection>
          <DefaultBtn disabled={keywordBtnDisabled} onClick={onClickKeyword}>
            {"입력 완료"}
          </DefaultBtn>
        </ButtonSection>
      )}
    </>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 3rem 1.6rem 20rem;
`;

const ButtonSection = styled.section`
  position: absolute;
  bottom: 0rem;
  width: 100%;
  padding: 0px 1.6rem 4rem;
`;

export default Chat;
