import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DefaultBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";

import { ReactComponent as AI } from "assets/2.0/AI.svg";
import { ReactComponent as Bubble } from "assets/2.0/bubble.svg";
import { ReactComponent as Title } from "assets/2.0/title.svg";
import Frame1Img from "assets/2.0/home/frame1.png";
import Frame2Img from "assets/2.0/home/frame2.png";
import Frame3Img from "assets/2.0/home/frame3.png";
import Frame4Img from "assets/2.0/home/frame4.png";
import Frame5Img from "assets/2.0/home/frame5.png";
import Frame6Img from "assets/2.0/home/frame6.png";
import Frame7Img from "assets/2.0/home/frame7.png";
import Frame8Img from "assets/2.0/home/frame8.png";
import Frame9Img from "assets/2.0/home/frame9.png";
import Frame10Img from "assets/2.0/home/frame10.png";
import styled, { css } from "styled-components";
import useAuth from "hooks/useAuth";

const animations = [
  // Gray box (frame : 0)
  css`
    opacity: ${({ $isVisible }: { $isVisible: boolean }) =>
      $isVisible ? 1 : 0};
    transition: opacity 0.6s cubic-bezier(0.5, 0, 0.88, 0.77);
  `,
  // Title (frame : 1)
  css`
    transform: translateY(
      ${(props: { $isVisible: boolean }) =>
        props.$isVisible ? "0rem" : "-4rem"}
    );
    transition: transform 0.5s ease-out 0.6s, opacity 0.5s ease-out 0.6s;
  `,
  // Card (frame : 2)
  css`
    &:first-child {
      transform: translateY(
        ${(props: { $isVisible: boolean }) =>
          props.$isVisible ? "0rem" : "-8rem"}
      );
      transition: transform 0.6s ease-out 0s, opacity 0.6s ease-out 0s;
    }
    &:last-child {
      transform: translateY(
        ${(props: { $isVisible: boolean }) =>
          props.$isVisible ? "0rem" : "1.8rem"}
      );
      transition: transform 0.5s cubic-bezier(0.5, 0, 0.88, 0.77) 0.2s,
        opacity 0.5s cubic-bezier(0.5, 0, 0.88, 0.77) 0.2s;
    }
  `,
  // QA (frame : 3)
  css`
    transform: translateY(
      ${(props: { $isVisible: boolean }) =>
        props.$isVisible ? "0rem" : "-8rem"}
    );
    transition: transform 0.6s ease-out 0s, opacity 0.6s ease-out 0s;
  `,
];

const Home = () => {
  const navigate = useNavigate();

  const { isLogined } = useAuth();

  const locationList = localStorage.getItem("location") ?? "";
  const keywordIdList = localStorage.getItem("keyword") ?? "";
  const names = localStorage.getItem("names") ?? "";

  const onClickHandler = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (isLogined) {
      if (!locationList && !keywordIdList && !names) {
        navigate("/chat", { replace: true });
      } else {
        navigate("/chat/result", { replace: true });
      }
    }
  }, [isLogined, locationList, keywordIdList, names, navigate]);

  return (
    <>
      <Container>
        <HeaderSection>
          <Title width={83} height={20} />
          <AI width={32} height={32} />
        </HeaderSection>
        {/* Main with floating icons */}
        <Frame1>
          <img alt={"frame1"} src={Frame1Img} />
          <Bubble width={54} height={54} />
          <AI width={54} height={54} />
        </Frame1>
        {/* Gray box */}
        <DetectedComponent frame={0}>
          <img alt={"frame2"} src={Frame2Img} />
        </DetectedComponent>
        {/* Title  */}
        <DetectedComponent frame={1}>
          <img alt={"frame3"} src={Frame3Img} />
        </DetectedComponent>
        {/* Card 1 */}
        <DetectedComponent frame={2}>
          <img alt={"frame4"} src={Frame4Img} />
          <img alt={"frame5"} src={Frame5Img} />
        </DetectedComponent>
        {/* Card 2 */}
        <DetectedComponent frame={2}>
          <img alt={"frame6"} src={Frame6Img} />
          <img alt={"frame7"} src={Frame7Img} />
        </DetectedComponent>
        {/* Card 3 */}
        <DetectedComponent frame={2}>
          <img alt={"frame8"} src={Frame8Img} />
          <img alt={"frame9"} src={Frame9Img} />
        </DetectedComponent>
        {/* QA */}
        <DetectedComponent frame={2}>
          <QAFrame>
            <img alt={"frame10"} src={Frame10Img} />
          </QAFrame>
        </DetectedComponent>
        <FooterSection>
          <div>
            <Title width={63} height={20} />
            <span>{"메타본 주식회사 | 대표 : 김진희"}</span>
            <span>
              {
                "주소: 서울 성동구 성수일로 3길 7 7층 | 사업자등록번호: 889-87-02611 | 통신판매업번호: 2022-경기김포-1702"
              }
            </span>
            <span>
              {"연락처 : 031-981-9092 | 이메일 : official@metabon.co"}
            </span>
          </div>
        </FooterSection>
      </Container>
      <ButtonSection>
        <DefaultBtn onClick={onClickHandler}>
          {"3분안에 일자리 찾기"}
        </DefaultBtn>
      </ButtonSection>
    </>
  );
};

const DetectedComponent = ({
  children,
  frame,
}: PropsWithChildren & { frame: number }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <FrameWrapper ref={ref} $isVisible={isVisible} $frame={frame}>
      {children}
    </FrameWrapper>
  );
};

const Container = styled.article`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const HeaderSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  padding: 1.1rem 1.6rem;
  border-bottom: 1px solid var(--color-gray-50);
`;

const Frame1 = styled.div`
  position: relative;
  margin: 0rem;

  img {
    width: 100%;
  }

  svg {
    position: absolute;

    &:nth-child(2) {
      top: 18%;
      left: 19%;
      animation: iconFloating 0.8s cubic-bezier(0.44, 0, 0.56, 1) 0s
        alternate-reverse infinite;
    }

    &:nth-child(3) {
      bottom: 26%;
      right: 18%;
      animation: iconFloating 0.8s cubic-bezier(0.44, 0, 0.56, 1) 0.4s
        alternate-reverse infinite;
    }

    @keyframes iconFloating {
      from {
        transform: translateY(-0.4rem);
      }
      to {
        transform: translateY(0rem);
      }
    }
  }
`;

const QAFrame = styled.div`
  margin: 2.4rem 0rem;
  img {
    width: 100%;
  }
`;

const FooterSection = styled.section`
  padding: 2.4rem 2.4rem 12rem;
  background-color: var(--color-gray-10);

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    span {
      ${typography.footer};
      color: var(--color-gray-600);
    }
  }
`;

const ButtonSection = styled.section`
  position: absolute;
  bottom: 0rem;
  width: 100%;
  padding: 0px 1.6rem 4rem;
`;

type FrameWrapperType = {
  $isVisible: boolean;
  $frame: number;
};

const FrameWrapper = styled.div<FrameWrapperType>`
  margin: ${({ $frame }) => ($frame === 0 ? "0rem" : "2.4rem 0rem")};

  img {
    width: 100%;
    opacity: ${({ $isVisible }: { $isVisible: boolean }) =>
      $isVisible ? 1 : 0};

    ${({ $frame }) => animations[$frame]};
  }
`;

export default Home;
