import request, { BaseResponse } from "apis/core";
import type { AxiosRequestConfig } from "axios";
import { KeywordSelectList } from "stores/jobSearchInfo";
import { AddressRequest } from "userAddressTypes";

export const getJobKeyword = (config?: AxiosRequestConfig) => {
  const url = "/app/user/job/keyword";
  return request.get<BaseResponse<[{ id: number; name: string }]>>(url, config);
};

export const getJobList = (
  { page }: { page: number },
  config?: AxiosRequestConfig
) => {
  const url = `/app/user/job?page=${page}`;
  return request.get<
    BaseResponse<{
      jobInfoList: {
        id: number;
        company: string;
        name: string;
        type: string;
        location: string;
        etc: string;
        salary: string;
        regDate: number[];
        deadline: number[];
      }[];
      nextUrl?: string;
    }>
  >(url, config);
};

export const getJobListCustom = ({
  page,
  orderKey,
}: {
  page: number;
  orderKey: "deadline" | "recent";
}) => {
  const url = `/app/user/job/custom?page=${page}&orderKey=${orderKey}`;
  return request.get<
    BaseResponse<{
      jobInfoList: {
        id: number;
        company: string;
        name: string;
        type: string;
        location: string;
        etc: string;
        salary: string;
        regDate: number[];
        deadline: number[];
      }[];
      nextUrl?: string;
      totalCount: number;
    }>
  >(url);
};

export type JobLocationRequest = {
  locationList: AddressRequest[];
};

export const postJobLocation = (
  data: JobLocationRequest,
  config?: AxiosRequestConfig
) => {
  const url = "/app/user/job/location";
  return request.post<
    BaseResponse<
      {
        createAt: number[];
        updateAt: number[];
        state: string;
        id: number;
        userId: number;
        city: string;
        district: string;
      }[]
    >
  >(url, data, config);
};

export type JobCareerRequest = {
  hasCareer: boolean;
  names: string[];
  careerKeywordIdList: number[];
};

export const postJobCareer = (
  data: JobCareerRequest,
  config?: AxiosRequestConfig
) => {
  const url = "/app/user/job/career";
  return request.post<
    BaseResponse<
      {
        userId: number;
        hasCareer: boolean;
        names: string[];
        locations: string[];
      }[]
    >
  >(url, data, config);
};

export const getJobDetail = (id: string, config?: AxiosRequestConfig) => {
  const url = `/app/user/job/${id}`;
  return request.get<
    BaseResponse<{
      id: 0;
      company: string;
      name: string;
      type: string;
      location: string;
      etc: string;
      salary: string;
      regDate: number[];
      deadline: number[];
      originUrl: string;
      reperNm: string;
      totPsncnt: string;
      busiSize: string;
      indTpCdNm: string;
      salTpCd: string;
      holidayTp: string;
      collectPsncnt: string;
      enterTpCd: string;
      eduNm: string;
      pfCond: string;
      etcPfCond: string;
      jobsNm: string;
    }>
  >(url, config);
};

export const getJobAlarm = (config?: AxiosRequestConfig) => {
  const url = "/app/user/job/alarm";
  return request.get<BaseResponse<boolean>>(url, config);
};

export const patchJobAlarm = (status: boolean, config?: AxiosRequestConfig) => {
  const url = `/app/user/job/alarm/${status}`;
  return request.patch<BaseResponse<string>>(url, config);
};

const headKey = ["job"];

export const jobKeys = {
  all: [...headKey] as const,
  getKeyword: [...headKey, "ketword"] as const,
  getJobList: (location: AddressRequest[], careerList: KeywordSelectList) =>
    [...headKey, "list", location, careerList] as const,
  getJobListCustom: (
    location: AddressRequest[],
    careerList: KeywordSelectList
  ) => [...headKey, "list", "custom", location, careerList] as const,
  getJobDetail: (id: string) => [...headKey, "detail", id] as const,
  getJobAlarm: [...headKey, "alarm"] as const,
};
