/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export type Set<State, Action> = (
  updater: (
    state: State & { action: Action }
  ) => Partial<State & { action: Action }>
) => void;

export function commonStoreForm<
  State extends Record<string, unknown>,
  Action extends Record<string, (value?: any) => void>
>(props: {
  initialState: State;
  action: (set: Set<State, Action>) => Action;
  persistName?: string;
}) {
  const { initialState, action, persistName } = props;

  const store = (set: Set<State, Action>) => ({
    ...initialState,
    action: { ...action(set) },
  });

  type StoreType = State & { action: Action };

  if (!!persistName) {
    return create<StoreType>()(
      persist(store, {
        name: persistName,
        partialize: ({ action, ...rest }: any) => rest,
      })
    );
  }

  return create<StoreType>()(
    process.env.REACT_APP_ENV !== "production" ? devtools(store) : store
  );
}
