import { useQuery } from "@tanstack/react-query";
import {
  DefaultLabel,
  DoubleBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { getJobDetail, jobKeys } from "apis/job";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Chevron } from "assets/2.0/chevronRight.svg";
import styled from "styled-components";
import { calculateDifferentDays } from "utils/date";

const MyResultDetail = () => {
  const { id = "" } = useParams();

  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: jobKeys.getJobDetail(id),
    queryFn: async () => await getJobDetail(id),
    select: (response) => response.data.result,
    enabled: !!id,
  });

  if (!data) return;

  const onClickShare = async () => {
    try {
      await navigator.share({
        title: `${data.name}(${data.company})`,
        url: window.location.href,
      });
    } catch (e) {
      console.error(e);
    }
  };
  const onClickUrl = () => {
    window.location.href = data.originUrl;
  };

  const DATA = [
    {
      title: "근무 조건",
      data: [
        { key: "위치", value: data?.location },
        {
          key: "요일",
          value: data?.holidayTp,
          emphasis: true,
        },
        {
          key: "급여",
          value: data.salary
            ? data.salTpCd
              ? `${data?.salTpCd} ${data?.salary}`
              : data.salary
            : data.salTpCd
            ? data?.salary
            : null,
          emphasis: true,
        },
      ],
    },
    {
      title: "모집 조건",
      data: [
        { key: "모집직종", value: data?.jobsNm },
        { key: "모집인원", value: data?.collectPsncnt },
        { key: "고용형태", value: data?.type },
        { key: "경력", value: data?.enterTpCd, emphasis: true },
        { key: "학력", value: data?.eduNm, emphasis: true },
        {
          key: "우대/기타",
          value: data.pfCond
            ? data.etcPfCond
              ? `${data?.pfCond}, ${data?.etcPfCond}`
              : data.pfCond
            : data.etcPfCond
            ? data?.etcPfCond
            : null,
        },
      ],
    },
    {
      title: "회사 정보",
      data: [
        { key: "회사명", value: data?.company },
        { key: "대표자명", value: data?.reperNm },
        { key: "근로자수", value: data?.totPsncnt },
        { key: "기업형태", value: data?.busiSize },
        { key: "업종", value: data?.indTpCdNm },
      ],
    },
  ];

  const calculateDate = calculateDifferentDays(
    new Date(data.deadline[0], data.deadline[1] - 1, data.deadline[2])
  );
  return (
    <>
      <Container>
        <Header>
          <button onClick={() => navigate(-1)}>
            <Chevron width={24} height={24} />
          </button>
          <span>{"상세보기"}</span>
        </Header>
        <Content>
          <TitleSection>
            <section>
              <DefaultLabel>{`마감까지 ${calculateDate}일`}</DefaultLabel>
              {/* <TextIconBtn SVGComponent={Send} onClick={onClickShare}>
                {"공유하기"}
              </TextIconBtn> */}
            </section>
            <p>{data?.name}</p>
          </TitleSection>
          {DATA.map((largeData, idx) => {
            return (
              <DetailCard key={idx}>
                <p>{largeData.title}</p>
                <ListContainer>
                  {largeData.data.map((eachData, eachIdx) => {
                    return (
                      <li key={eachIdx}>
                        <p>{eachData.key ?? "-"}</p>
                        <span
                          className={
                            !!eachData.emphasis ? "jobDetailSpanEmphasis" : ""
                          }
                        >
                          {eachData.value ?? "-"}
                        </span>
                      </li>
                    );
                  })}
                </ListContainer>
              </DetailCard>
            );
          })}
        </Content>
      </Container>
      <ButtonSection>
        <DoubleBtn
          onClickShort={onClickShare}
          textShort={"공유하기"}
          onClickLong={onClickUrl}
          textLong={"채용 링크 바로가기"}
        />
      </ButtonSection>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  padding: 1.6rem;
  border-bottom: 1px solid var(--color-gray-50);

  button {
    background-color: transparent;
    border: none;

    svg {
      color: var(--color-gray-900);
      transform: rotateY(180deg);
    }
  }

  span {
    ${typography.heading2};
    color: var(--color-gray-900);
  }
`;

const Content = styled.article`
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  padding-bottom: 15rem;
  overflow-y: scroll;

  & > section {
    border-bottom: 1px solid var(--color-gray-50);
  }
`;

const TitleSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 0.8rem 1.6rem;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p {
    ${typography.heading1};
    color: var(--color-gray-900);
  }
`;

const DetailCard = styled.section`
  padding: 1.6rem;

  & > p {
    margin-bottom: 1rem;
    ${typography.heading3};
    color: var(--color-gray-900);
  }
`;

const ListContainer = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin: 1rem 0px;

    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }

    p {
      flex: 0.4;
      ${typography.preTitle};
      color: var(--color-gray-500);
      text-align: left;
    }
    span {
      flex: 1;
      ${typography.body2};
      color: var(--color-gray-900);
      text-align: left;

      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;

      display: -webkit-box;
      -webkit-line-clamp: 2; // 원하는 라인수
      -webkit-box-orient: vertical;
    }

    .jobDetailSpanEmphasis {
      color: var(--color-yellow-5);
    }
  }
`;

const ButtonSection = styled.section`
  position: absolute;
  bottom: 0rem;
  width: 100%;
  padding: 0px 1.6rem 4rem;
`;

export default MyResultDetail;
