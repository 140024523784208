import DimmedLoading from "components/Common/Loading/DimmedLoading";
import SolidLoading from "components/Common/Loading/SolidLoading";
import React, { useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import routes from "routes";
import useLoadingStore from "stores/loading";
import styled from "styled-components";
import {
  BottomSheet,
  DefaultPopup,
  DimmedScreen,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import useModalState from "stores/modal";
import trackScreenView from "utils/firebase/trackScreen";

const App = () => {
  const location = useLocation();

  const [previousParams, setPreviousParams] = useState<string>("");

  const isOpenSolidLoading = useLoadingStore(
    (state) => state.isOpenSolidLoading
  );
  const isOpenDimmedLoading = useLoadingStore(
    (state) => state.isOpenDimmedLoading
  );
  // const isOpen = usePopupStore((state) => state.isOpen);
  // const popupProps = usePopupStore((state) => state);
  const solidText = useLoadingStore((state) => state.solidText);

  const defaultPopup = useModalState((state) => state.defaultPopup);
  const secondaryPopup = useModalState((state) => state.secondaryPopup);
  const bottomSheet = useModalState((state) => state.bottomSheet);
  const { closeAll } = useModalState((state) => state.action);

  const isDimmedOpen: boolean = useMemo(() => {
    if (defaultPopup?.isOpen || secondaryPopup?.isOpen || bottomSheet?.isOpen)
      return true;
    else return false;
  }, [defaultPopup.isOpen, secondaryPopup.isOpen, bottomSheet.isOpen]);

  useEffect(() => {
    if (!isDimmedOpen) return;

    // window.history.pushState(null, "");

    const handleBackPress = () => {
      closeAll();

      // window.history.pushState(null, "");
    };

    window.addEventListener("popstate", handleBackPress);

    return () => {
      window.removeEventListener("popstate", handleBackPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDimmedOpen]);

  useEffect(() => {
    if (!location || !location.pathname) return;
    if (location.pathname === previousParams) return;

    setPreviousParams(location.pathname);

    // Prevent login page
    if (location.pathname.includes("/login/callback")) return;

    trackScreenView(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Routes>
      <Route path={"*"} element={<>Not Found</>} />
      {routes.map(
        (route, idx) =>
          route.element && (
            <Route
              key={idx}
              path={route.path}
              element={
                <DefaultLayout>
                  <route.element />
                  {isOpenSolidLoading && <SolidLoading text={solidText} />}
                  {isOpenDimmedLoading && <DimmedLoading />}
                  {/* Modal */}
                  <DimmedScreen isOpen={isDimmedOpen} onClose={closeAll} />
                  {defaultPopup.isOpen && <DefaultPopup {...defaultPopup} />}
                  {secondaryPopup.isOpen && (
                    <DefaultPopup {...secondaryPopup} />
                  )}
                  {bottomSheet.isOpen && <BottomSheet {...bottomSheet} />}
                </DefaultLayout>
              }
            />
          )
      )}
    </Routes>
  );
};

const DefaultLayout = styled.main`
  position: relative;
  max-width: 41.2rem;
  height: 100dvh;
  background-color: var(--color-white);
  margin: auto;
  overflow: hidden;
`;

export default App;
